import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm } from "../../../../hooks";

import { FileInput, IconButton } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormArchivos.module.css';

export const ArchivoForm = ({ addElement, element, last, formStatus='checked', onFormChange, removeElement }) => {

  const { archivo, onInputChange } = useForm({ archivo: element.archivo });

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, archivo, validation: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivo])

  return (
    <div className="row">
      <div className="col-md-12 col-lg-10 col-xl-11">
        <FileInput
          formFieldStatus="checked"
          label="Archivo"
          name="archivo"
          onChange={ onInputChange }
          required={false}
          requiredIndicator={false}
          value={ archivo }
        />
      </div>
      {(last)
      ? <div className={"col-md-12 col-lg-2 col-xl-1"}>
        <div className={styles.closeBtnContainer}>
          <div className="centrar-md">
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="Plus"
              iconColor={colors.PRIMARY}
              marginTop={false}
              onClick={addElement}
              type="button"
            />
          </div>
        </div>
      </div>
      : <div className={"col-md-12 col-lg-2 col-xl-1"}>
        <div className={styles.closeBtnContainer}>
          <div className="centrar-md">
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="X"
              iconColor={colors.DANGER}
              marginTop={false}
              onClick={() => removeElement(element.id)}
              type="button"
            />
          </div>
        </div>
      </div>
      }
    </div>
  )
}

ArchivoForm.propTypes = {
  addElement: PropTypes.func.isRequired,
  element: PropTypes.any.isRequired,
  last: PropTypes.bool.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}