import PropTypes from 'prop-types';

import { PrimaryButton, LightButton } from '../../../components';

import styles from '../Modals.module.css';

export const LimiteInmunoterapiaModal = ({ acept, reject }) => {

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className={styles.modalHeader}>Límite superado</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
            <div className="row">
              <div className="col mt-3 mb-3">
                <p>Inmunoterapia fuera del límite de aplicación, considere su finalización. ¿Desea continuar?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                  block={true}
                  icon="X"
                  marginTop={false}
                  onClick={ reject }
                  text="Cancelar"
                  type="button"
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                <PrimaryButton
                  block={true}
                  icon="Check"
                  marginTop={false}
                  onClick={ acept }
                  text="Continuar"
                  type="button"
                />
              </div>
            </div>
            <div className="row">
              <div className="col mt-3 mb-3">
                <p>En caso de continuar, el limite de aplicacion será omitido durante la consulta.</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

LimiteInmunoterapiaModal.propTypes = {
  acept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
}