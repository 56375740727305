import React, { useState } from 'react'

import { Radiologia } from './components/Radiologia';
import { Laboratorio } from './components/Laboratorio';
import { PruebaAlergia } from './components/PruebaAlergia';
import { Inmunoterapia } from './components/Inmunoterapia';

import styles from './PacienteConsulta.module.css';

export const PacienteConsulta = () => {

  const [tab, setTab] = useState(1);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-8">Consulta</div>
        <div className="col-sm-12 col-md-4">
          <div className={styles.tabsContainer}>
            <div className={"row"}>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 1) ? styles.tabInmunoterapiasActive : styles.tabInmunoterapias)} onClick={() => setTab(1)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 2) ? styles.tabPruebasActive : styles.tabPruebas)} onClick={() => setTab(2)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 3) ? styles.tabLaboratoriosActive : styles.tabLaboratorios) } onClick={() => setTab(3)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 4) ? styles.tabRadiologiaActive : styles.tabRadiologia) } onClick={() => setTab(4)}></div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              {(tab === 1) && <div className="col"><Inmunoterapia /></div>}
              {(tab === 2) && <div className="col"><PruebaAlergia /></div>}
              {(tab === 3) && <div className="col"><Laboratorio /></div>}
              {(tab === 4) && <div className="col"><Radiologia /></div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
