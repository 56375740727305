import { useQuery } from "@tanstack/react-query"

import { getFrasco } from "../services/inmunoterapia.service";

export const useInmunoterapiaFrasco = (tipo, fase) => {

    const frascoQuery = useQuery({
        queryKey: ['inmunoterapiaFrasco'],
        queryFn: () => getFrasco(tipo, fase),
        staleTime: 0, // nulo
    });

    return {
        frascoQuery,
    }
}