import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createPacienteInmunoterapia } from "../services/inmunoterapia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteInmunoterapiaCrear = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const createInmunoterapiaMutation = useMutation({
        mutationFn: createPacienteInmunoterapia,
        onSuccess: (inmunoterapia, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/proxima', id] });
            variables.success();
            dispatch(successNotification('Inmunoterapia creada'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        createInmunoterapiaMutation,
    }
}