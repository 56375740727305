import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { deletePacienteInmunoterapiaAplicacion } from "../services/inmunoterapia.service";

import { dangerNotification } from '../../../redux/slices/ui';

export const usePacienteInmunoterapiaAplicacionEliminar = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const deleteInmunoterapiaAplicacionMutation = useMutation({
        mutationFn: deletePacienteInmunoterapiaAplicacion,
        onSuccess: ({inmunoterapia, caducidad, limite}, variables, context) => {
            if(caducidad){
                variables.caducidad();
            }else if (limite){
                variables.limite();
            }else{
                variables.resetAccion();
                queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/proxima', id] });
                variables.success(inmunoterapia.aplicaciones);
            }
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        deleteInmunoterapiaAplicacionMutation,
    }
}