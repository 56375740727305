import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import styles from './Empty.module.css';

export const Empty = () => {
  return (
    <>
        <div className='mt-4 mb-2'>
            <div className='centrar'>
                <Icon.ListNested className={styles.icon} />
            </div>
            <div className={styles.text}>Listado vacio</div>
        </div>
    </>
  )
}
