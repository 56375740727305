import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { AplicacionForm } from './components/Aplicacion';
import { PrimaryRoundedButton } from '../..';

export const MultiFormAplicacionInmunoterapiaNueva = ({ formsStatus='checked', ladoFlag = true, lados = [], name, onFormsChange, reaccionFlag = true, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val.id, fecha: val.fecha, dosis: val.dosis, ladoAplicacion: val.ladoAplicacion, reaccion: val.reaccion, realizado: val.realizado, validation: true })) : []);

    const handleAddElement = () => {
        let now = new Date();
        let formatted = now.toLocaleString('sv-SE', { timeZone: 'America/Mexico_City' }).replace('T', ' ').slice(0, 16);
        setElements([...elements, { id: moment().unix() + Math.random(), fecha: formatted, dosis: elements[elements.length - 1].dosis, ladoAplicacion: (elements[elements.length - 1].ladoAplicacion === 'Derecho') ? 'Izquierdo' : 'Derecho', reaccion: '', realizado: false, validation: false }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            id: element.id,
            fecha: element.fecha,
            dosis: element.dosis,
            ladoAplicacion: element.ladoAplicacion,
            reaccion: element.reaccion,
            realizado: element.realizado,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            {elements.map((element, index) => <AplicacionForm
                element={element}
                first={index === 0}
                formStatus={formsStatus}
                index={index + 1}
                key={index}
                ladoFlag={ladoFlag}
                lados={lados}
                last={index === elements.length - 1}
                onFormChange={handleSetElementData}
                reaccionFlag={reaccionFlag}
                removeElement={handleRemoveElement}
            />)}
            <div className='centrar'>
                <PrimaryRoundedButton
                    icon="Plus"
                    onClick={handleAddElement}
                    type="button"
                />
            </div>
        </>
    )
}

MultiFormAplicacionInmunoterapiaNueva.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    ladoFlag: PropTypes.bool,
    lados: PropTypes.array,
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    reaccionFlag: PropTypes.bool,
    setFormValidation: PropTypes.func,
    value: PropTypes.array,

}