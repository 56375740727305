import { useQuery } from "@tanstack/react-query"

import { getSitio } from "../services/inmunoterapia.service";

export const useInmunoterapiaSitio = (tipo) => {

    const sitioQuery = useQuery({
        queryKey: ['inmunoterapiaSitio'],
        queryFn: () => getSitio(tipo),
        staleTime: 0, // nulo
    });

    return {
        sitioQuery,
    }
}