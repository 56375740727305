import { useQuery } from "@tanstack/react-query"

import { getSitio } from "../services/pruebaalergia.service";

export const useSitio = (tipoPrueba, prueba) => {

    const sitioQuery = useQuery({
        queryKey: ['pruebaAlergiaSitio'],
        queryFn: () => getSitio(tipoPrueba, prueba),
        staleTime: 0, // nulo
    });

    return {
        sitioQuery,
    }
}