import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { IconButton, NumberInput } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormAplicacionesGotas.module.css';

export const AplicacionForm = ({ element, first, formStatus='checked', onFormChange, removeElement }) => {

  const { dosis, repeticiones, onInputChange } = useForm({ dosis: element.dosis, repeticiones: element.repeticiones });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'dosis', default: true}, {field: 'repeticiones', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, dosis, repeticiones, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dosis, repeticiones, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  return (
    <>
        <div className="row">
            <div className="col">
                <NumberInput
                    formFieldStatus={ formFields['dosis'].status }
                    label="Gotas"
                    name="dosis"
                    onChange={ onInputChange }
                    required={ false }
                    requiredIndicator={ false }
                    setFormFieldValidation={ setFormFieldValidation }
                    step={1}
                    value={ dosis }
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <NumberInput
                    formFieldStatus={ formFields['repeticiones'].status }
                    label="Aplicaciones"
                    name="repeticiones"
                    onChange={ onInputChange }
                    required={ false }
                    requiredIndicator={ false }
                    setFormFieldValidation={ setFormFieldValidation }
                    step={1}
                    value={ repeticiones }
                />
            </div>
        </div>
        {(!first) &&
        <div className="row">
            <div className={"col"}>
                <div className={styles.closeBtnContainer}>
                    <div>
                        <IconButton
                            icon="Trash3"
                            iconColor={colors.DANGER}
                            marginTop={false}
                            onClick={() => removeElement(element.id)}
                            type="button"
                            size={28}
                        />
                    </div>
                </div>
            </div>
        </div>
        }
    </>
  )
}

AplicacionForm.propTypes = {
  element: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}