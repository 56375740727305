import { useQuery } from "@tanstack/react-query"

import { getPacienteInmunoterapiaProxima } from "../services/inmunoterapia.service";

export const usePacienteInmunoterapiaProxima = (id) => {

    const pacienteInmunoterapiaProximaQuery = useQuery({
        queryKey: ['paciente/inmunoterapia/proxima', id],
        queryFn: () => getPacienteInmunoterapiaProxima(id),
        staleTime: 1000 * 60 * 5, // 5 minutos
    });

    return {
        pacienteInmunoterapiaProximaQuery,
    }
}