import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const TextInputPreview = ({ label, marginBottom = true, name, showLabel = true, value = ''}) => {
  
  return (
    <>
      {(showLabel) && 
      <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }</label>
      }
      <div className={(marginBottom) ? styles.customPreview : styles.customPreviewNoMargin}>{ value }</div>
    </>
  )
}

TextInputPreview.propTypes = {
  label: PropTypes.string,
  marginBottom: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  value: PropTypes.string,
}