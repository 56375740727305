import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const SelectKeyInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, required = false, requiredIndicator = false, setFormFieldValidation, showLabel = true, value, values}) => {

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(values.map(val => val.value).includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value !== ''){
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                // setInputValidation({ isValid: null, msg: null });
                if(required){
                    setInputValidation({ isValid: false, msg: 'Selección no válida' });
                    if(setFormFieldValidation) setFormFieldValidation(name, false);
                }else{
                    setInputValidation({ isValid: true, msg: null });
                    if(setFormFieldValidation) setFormFieldValidation(name, true);
                }
            }
        }
    });

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(values.map(val => val.value).includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value === '' || value === undefined){
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
            }else{
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return (
        <>
            {(showLabel) && <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <select className={"form-select " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} name={ name } onChange={ onChange } value={(value !== undefined) ? value : ""} required={required}>
            {/* <select className={"form-select " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} name={ name } onChange={ onChange } defaultValue={(value !== undefined) ? value : ""} required={required}> */}
                <option value="" disabled>{placeholder}</option>
                { values.map(option => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                }) }
            </select>
            <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

SelectKeyInput.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    setFormFieldValidation: PropTypes.func,
    showLabel: PropTypes.bool,
    value: PropTypes.string,
    values: PropTypes.array.isRequired,
}