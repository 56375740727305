import { inmunetApi } from '../../../api';

export const getTipo = async() => {

    try {

        const { data } = await inmunetApi.post(`/pruebaAlergia/tipos`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.tipos;

    } catch (error) {

        throw new Error(error);

    }

};

export const getPrueba = async(tipoPrueba) => {

    try {

        if (tipoPrueba === '') return [];

        let formData = new FormData();
        formData.append(`tipoPrueba`, tipoPrueba);

        const { data } = await inmunetApi.post(`/pruebaAlergia/pruebas`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.pruebas;

    } catch (error) {

        throw new Error(error);

    }

};

export const getDispositivo = async(tipoPrueba) => {

    try {

        if (tipoPrueba === '') return [];

        let formData = new FormData();
        formData.append(`tipoPrueba`, tipoPrueba);

        const { data } = await inmunetApi.post(`/pruebaAlergia/dispositivos`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.dispositivos;

    } catch (error) {

        throw new Error(error);

    }

};

export const getSitio = async(tipoPrueba, prueba) => {

    try {

        if (tipoPrueba === '' || prueba === '') return [];

        let formData = new FormData();
        formData.append(`tipoPrueba`, tipoPrueba);
        formData.append(`prueba`, prueba);

        const { data } = await inmunetApi.post(`/pruebaAlergia/sitios`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.sitios;

    } catch (error) {

        throw new Error(error);

    }

};

export const getAlergeno = async(prueba) => {

    try {

        if (prueba === '') return [];

        let formData = new FormData();
        formData.append(`prueba`, prueba);

        const { data } = await inmunetApi.post(`/pruebaAlergia/alergenos?paginacion=false`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.alergenos;

    } catch (error) {

        throw new Error(error);

    }

};

export const getPacientePruebaAlergia = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/pruebaAlergia/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.pruebaAlergia;

    } catch (error) {

        throw new Error(error);

    }

};

export const createPacientePruebaAlergia = async({ data: prueba, id }) => {

    try {

        let formData = new FormData();
        Object.keys(prueba).forEach(field => {
            if (field === 'archivos') {
                prueba[field].forEach((file, index) => {
                    formData.append(`archivos[${index}]`, prueba[field][index]);
                });
            } else {
                formData.append(field, prueba[field]);
            }
        });

        const { data } = await inmunetApi.post(`/pruebaAlergia/${id}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear prueba de alergia';

        return data.pruebaAlergia;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};