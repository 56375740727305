import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

import styles from './FormFields.module.css';

export const DateTimeInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, required = false, requiredIndicator = false, reset, setFormFieldValidation, showLabel = true, value}) => {

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        let values = [];
        if(value.includes('T')){
            values = value.split('T');
        }else{
            values = value.split(' ');
        }
        if(validator.isDate(values[0]) && validator.isTime(values[1])){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value !== ''){
                setInputValidation({ isValid: false, msg: 'Fecha no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                // setInputValidation({ isValid: null, msg: null });
                if(required){
                    setInputValidation({ isValid: false, msg: 'Fecha no válida' });
                    if(setFormFieldValidation) setFormFieldValidation(name, false);
                }else{
                    setInputValidation({ isValid: true, msg: null });
                    if(setFormFieldValidation) setFormFieldValidation(name, true);
                }
            }
        }
    })

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        setInputValidation({ isValid: null, msg: null });
    }, [reset])

    const handleOnChange = (e) => {
        let values = e.target.value.split('T');
        onChange({ target: { name: name, value: values.join(' ') }, event: e });
    }

    // useEffect(() => {
    //     setInputValidation({ isValid: null, msg: null });
    //     if(setFormFieldValidation) setFormFieldValidation(name, null);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
  
    return (
        <>
            {(showLabel) && <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <input type="datetime-local" pattern="\d{4}-\d{2}-\d{2} \d{2}:\d{2}" className={"form-control " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ handleOnChange } required={required} />
            <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

DateTimeInput.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    reset: PropTypes.number,
    setFormFieldValidation: PropTypes.func,
    showLabel: PropTypes.bool,
    value: PropTypes.string,
}