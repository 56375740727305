import { inmunetApi } from '../../../../../api';

export const getSubcutanea = async() => {

    try {

        const { data } = await inmunetApi.get('/configuracionExpedienteClinico/subcutanea');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.subcutanea;

    } catch (error) {

        throw new Error(error);

    }

};

export const updateSubcutanea = async({ data: subcutanea }) => {

    try {

        const { data } = await inmunetApi.put(`/configuracionExpedienteClinico/subcutanea`, {...subcutanea });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.subcutanea;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};