import { useQuery } from "@tanstack/react-query"

import { getReaccion } from "../services/inmunoterapia.service";

export const useInmunoterapiaReaccion = (tipo) => {

    const reaccionQuery = useQuery({
        queryKey: ['inmunoterapiaReaccion'],
        queryFn: () => getReaccion(tipo),
        staleTime: 0, // nulo
    });

    return {
        reaccionQuery,
    }
}