import { useQuery } from "@tanstack/react-query"

import { getLado } from "../services/inmunoterapia.service";

export const useInmunoterapiaLado = (tipo) => {

    const ladoQuery = useQuery({
        queryKey: ['inmunoterapiaLado'],
        queryFn: () => getLado(tipo),
        staleTime: 0, // nulo
    });

    return {
        ladoQuery,
    }
}