import { useQuery } from "@tanstack/react-query"

import { getRadiologia } from "../services/radiologia.service";

export const useRadiologia = () => {

    const radiologiaQuery = useQuery({
        queryKey: ['radiologia'],
        queryFn: () => getRadiologia(),
        staleTime: 0, // nulo
    });

    return {
        radiologiaQuery,
    }
}