import { useQuery } from "@tanstack/react-query"

import { getPacienteInmunoterapiaPrevia } from "../services/inmunoterapia.service";

export const usePacienteInmunoterapiaPrevia = (id) => {

    const pacienteInmunoterapiaPreviaQuery = useQuery({
        queryKey: ['paciente/inmunoterapia/previa', id],
        queryFn: () => getPacienteInmunoterapiaPrevia(id),
        staleTime: 1000 * 60 * 5, // 5 minutos
    });

    return {
        pacienteInmunoterapiaPreviaQuery,
    }
}