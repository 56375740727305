import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const DiasSemanaSelector = ({ formFieldStatus='checked', label, name, onChange, required = false, requiredIndicator = false, setFormFieldValidation, value }) => {

  const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

  const [inputMessage, setInputMessage] = useState({ exists: false, msg: '' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValidation = useCallback(() => {
    if(required){
        if(value.lunes || value.martes || value.miercoles || value.jueves || value.viernes || value.sabado || value.domingo){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            setInputValidation({ isValid: false, msg: 'Selecciona al menos un día' });
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }
    }else{
        setInputValidation({ isValid: true, msg: null });
        if(setFormFieldValidation) setFormFieldValidation(name, true);
    }
  })

const handleInputChange = (key) => {
    let newValues = { ...value, [key]: !value[key] };
    if(newValues.lunes || newValues.martes || newValues.miercoles || newValues.jueves || newValues.viernes || newValues.sabado || newValues.domingo){
        onChange({ target: { name: name, value: newValues } });
        setInputMessage({ exists: false, msg: '' });
    }else{
        setInputMessage({ exists: true, msg: 'Debes seleecionar por lo menos un día' });
    }
}

  useEffect(() => {
    if(formFieldStatus === 'checking') handleInputValidation();
  }, [formFieldStatus, handleInputValidation]);
  
  return (
    <>
        <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
        <div className="row">
            <div className="col">
                <div className={(value.lunes) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('lunes')}><div>L</div></div>
            </div>
            <div className="col">
                <div className={(value.martes) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('martes')}><div>M</div></div>
            </div>
            <div className="col">
                <div className={(value.miercoles) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('miercoles')}><div>M</div></div>
            </div>
            <div className="col">
                <div className={(value.jueves) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('jueves')}><div>J</div></div>
            </div>
            <div className="col">
                <div className={(value.viernes) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('viernes')}><div>V</div></div>
            </div>
            <div className="col">
                <div className={(value.sabado) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('sabado')}><div>S</div></div>
            </div>
            <div className="col">
                <div className={(value.domingo) ? styles.diaSelectorActive : styles.diaSelector} onClick={() => handleInputChange('domingo')}><div>D</div></div>
            </div>
        </div>
        <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        <div className={styles.helperMessageText + " mt-2" + ((inputMessage.exists) ? "" : " d-none" )}>{inputMessage.msg}</div>
    </>
  )
}

DiasSemanaSelector.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.object,
}