import { useQuery } from "@tanstack/react-query"

import { getFrascoData } from "../services/inmunoterapia.service";

export const useInmunoterapiaFrascoData = (tipo, fase, frasco, fecha) => {

    const frascoDataQuery = useQuery({
        queryKey: ['inmunoterapiaFrascoData'],
        queryFn: () => getFrascoData(tipo, fase, frasco, fecha),
        staleTime: 0, // nulo
    });

    return {
        frascoDataQuery,
    }
}