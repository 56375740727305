import { useQuery } from "@tanstack/react-query"

import { getLaboratorio } from "../services/laboratorio.service";

export const useLaboratorio = () => {

    const laboratorioQuery = useQuery({
        queryKey: ['laboratorio'],
        queryFn: () => getLaboratorio(),
        staleTime: 0, // nulo
    });

    return {
        laboratorioQuery,
    }
}