import { useQuery } from "@tanstack/react-query"

import { getTipo } from "../services/inmunoterapia.service";

export const useInmunoterapiaTipo = () => {

    const tipoQuery = useQuery({
        queryKey: ['inmunoterapiaTipo'],
        queryFn: () => getTipo(),
        staleTime: 0, // nulo
    });

    return {
        tipoQuery,
    }
}