import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { AlergenoInmunoterapiaInput, IconButton } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormAlergenosInmunoterapia.module.css';

export const AlergenoForm = ({ element, formStatus='checked', onFormChange, tipo, removeElement }) => {

  const { alergeno, onInputChange } = useForm({ alergeno: element.alergeno });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'alergeno', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, alergeno, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alergeno, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(alergeno !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
        <div className="col-md-12 col-lg-11">
            <AlergenoInmunoterapiaInput
              formFieldStatus={ formFields['alergeno'].status }
              isClearable={true}
              label=""
              name="alergeno"
              onChange={ onInputChange }
              tipo={tipo}
              required={false}
              requiredIndicator={false}
              setFormFieldValidation={ setFormFieldValidation }
              value={alergeno}
            />
        </div>
        <div className={"col-md-12 col-lg-1"}>
            <div className={styles.closeBtnContainer}>
                <div>
                    <IconButton
                        icon="Trash3"
                        iconColor={colors.DANGER}
                        marginTop={false}
                        onClick={() => removeElement(element.id)}
                        type="button"
                        size={20}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

AlergenoForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  tipo: PropTypes.string.isRequired,
  removeElement: PropTypes.func.isRequired,
}