import PropTypes from 'prop-types';

import { PrimaryButton, LightButton } from '../../../components';

import styles from '../Modals.module.css';

export const PushInmunoterapiaModal = ({ acept, reject }) => {

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className={styles.modalHeader}>Reprogramación de aplicaciones</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
            <div className="row">
              <div className="col mt-3 mb-3">
                <p>Actualizar la fecha de una aplicación puede desconfigurar la periodicidad de las mismas. ¿Desea reprogramar las aplicaciones posteriores para garantizar la frecuencia configurada?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                  block={true}
                  marginTop={false}
                  onClick={ reject }
                  text="Actualizar solo la aplicación seleccionada"
                  type="button"
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                <PrimaryButton
                  block={true}
                  marginTop={false}
                  onClick={ acept }
                  text="Reprogramar aplicaciones"
                  type="button"
                />
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

PushInmunoterapiaModal.propTypes = {
  acept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
}