import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm, useFormValidation } from '../../../hooks';
import { useInmunoterapiaTipo } from '../hooks/useInmunoterapiaTipo';
import { useInmunoterapiaFase } from '../hooks/useInmunoterapiaFase';
import { useInmunoterapiaSitio } from '../hooks/useInmunoterapiaSitio';
import { useInmunoterapiaLado } from '../hooks/useInmunoterapiaLado';
import { useInmunoterapiaReaccion } from '../hooks/useInmunoterapiaReaccion';
import { useInmunoterapiaFrasco } from '../hooks/useInmunoterapiaFrasco';
import { useInmunoterapiaFrascoData } from '../hooks/useInmunoterapiaFrascoData';
import { usePacienteInmunoterapiaCrear } from '../hooks/usePacienteInmunoterapiaCrear';

import { DateTimeInput, Error, LightButton, Loader, MultiFormAlergenosInmunoterapia, MultiFormAplicacionInmunoterapiaNueva, PrimaryButton, SelectInput, TextAreaInput, TextInput } from '../../../components';

import styles from '../PacienteConsulta.module.css';

export const InmunoterapiaNueva = ({ handleCancelar, id }) => {

    const { createInmunoterapiaMutation: { mutate: createInmunoterapia, isPending } } = usePacienteInmunoterapiaCrear(id);

    const [faseReset, setFaseReset] = useState(true);

    const [frascoReset, setFrascoReset] = useState(true);

    const [sitioReset, setSitioReset] = useState(true);

    const [alergenosReset, setAlergenosReset] = useState(true);

    const [aplicacionesReset, setAplicacionesReset] = useState(true);

    const { tipo, fase, fecha, frasco, concentracion, volumenAlergeno, sitio, alergenos, aplicaciones, comentarios, onInputChange, onMultipleInputChange } = useForm({ tipo: '', fase: '', fecha: '', frasco: '', concentracion: '', volumenAlergeno: '', sitio: '', alergenos: [{id: moment().unix(), alergeno: ''}], aplicaciones: [], comentarios: '' });

    const { isFormValid: isFormStaticValid, formValidationStatus: formStaticValidationStatus, formFields: formStaticFields, startFormValidation: startFormStaticValidation, setFormFieldValidation: setFormStaticFieldValidation, setMultipleFormFieldValidation: setMultipleFormStaticFieldValidation } = useFormValidation([{field: 'fecha', default: false}, {field: 'concentracion', default: true}, {field: 'volumenAlergeno', default: true}, {field: 'comentarios', default: true}]);

    const { isFormValid: isFormTipoValid, formValidationStatus: formTipoValidationStatus, formFields: formTipoFields, startFormValidation: startFormTipoValidation, setFormFieldValidation: setFormTipoFieldValidation } = useFormValidation([{field: 'tipo', default: false}]);

    const { isFormValid: isFormFaseValid, formValidationStatus: formFaseValidationStatus, formFields: formFaseFields, startFormValidation: startFormFaseValidation, setFormFieldValidation: setFormFaseFieldValidation } = useFormValidation([{field: 'fase', default: false}]);
    
    const { isFormValid: isFormFrascoValid, formValidationStatus: formFrascoValidationStatus, formFields: formFrascoFields, startFormValidation: startFormFrascoValidation, setFormFieldValidation: setFormFrascoFieldValidation } = useFormValidation([{field: 'frasco', default: false}]);

    const { formValidationStatus: formAlergenosValidationStatus, setFormFieldValidation: setFormAlergenosFieldValidation } = useFormValidation([{field: 'alergenos', default: true}]);

    const { formValidationStatus: formAplicacionesValidationStatus, setFormFieldValidation: setFormAplicacionesFieldValidation } = useFormValidation([{field: 'aplicaciones', default: true}]);

    const { tipoQuery: { isLoading, isError, data: tipos } } = useInmunoterapiaTipo();

    const { faseQuery: { data: fases, refetch: refetchFase } } = useInmunoterapiaFase(tipo);

    const { sitioQuery: { data: sitioData, refetch: refetchSitio } } = useInmunoterapiaSitio(tipo);

    const { ladoQuery: { data: ladoData, refetch: refetchLado } } = useInmunoterapiaLado(tipo);

    const { reaccionQuery: { data: reaccion, refetch: refetchReaccion } } = useInmunoterapiaReaccion(tipo);

    const { frascoQuery: { data: frascos, refetch: refetchFrasco } } = useInmunoterapiaFrasco(tipo, fase);

    const { frascoDataQuery: { data: frascoData, refetch: refetchFrascoData } } = useInmunoterapiaFrascoData(tipo, fase, frasco, fecha);

    const handleResetFase = () => {
        setFaseReset(false);
        setTimeout(() => {
            setFaseReset(true);
        }, 1);
    }

    const handleResetSitio = () => {
        setSitioReset(false);
        setTimeout(() => {
            setSitioReset(true);
        }, 1);
    }

    const handleResetFrasco = () => {
        setFrascoReset(false);
        setTimeout(() => {
            setFrascoReset(true);
        }, 1);
    }

    const handleResetAlergenos = () => {
        setAlergenosReset(false);
        setTimeout(() => {
            setAlergenosReset(true);
        }, 1);
    }

    const handleResetAplicaciones = () => {
        setAplicacionesReset(false);
        setTimeout(() => {
            setAplicacionesReset(true);
        }, 1);
    }

    const handleOnFieldChange = (e) => {
        onInputChange(e);
    }

    useEffect(() => {
        refetchFase();
        refetchSitio();
        refetchLado();
        refetchReaccion();
        let tipoStatus = false;
        if(typeof tipos !== 'undefined' && tipos.includes(tipo)){
            tipoStatus = true;
        }else if(tipo === ''){
            tipoStatus = null;
        }
        onMultipleInputChange([{ target: { name: 'fase', value: '' } }, { target: { name: 'sitio', value: '' } }, { target: { name: 'alergenos', value: [{id: moment().unix(), alergeno: ''}] } }]);
        setFormTipoFieldValidation('tipo', tipoStatus);
        setFormFaseFieldValidation('fase', null);
        setFormAlergenosFieldValidation('alergenos', true);
        handleResetFase();
        handleResetSitio();
        handleResetAlergenos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo]);

    useEffect(() => {
        refetchFrasco();
        let faseStatus = false;
        if(typeof fases !== 'undefined' && fases.includes(fase)){
            faseStatus = true;
        }else if(fase === ''){
            faseStatus = null;
        }
        onMultipleInputChange([{ target: { name: 'frasco', value: '' } }]);
        setFormFaseFieldValidation('fase', faseStatus);
        setFormFrascoFieldValidation('frasco', null);
        handleResetFrasco();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fase]);

    useEffect(() => {
        refetchFrascoData();
        let frascoStatus = false;
        if(typeof frascos !== 'undefined' && frascos.map(frasco => frasco.frasco).includes(frasco)){
            frascoStatus = true;
        }else if(frasco === ''){
            frascoStatus = null;
        }
        setFormFrascoFieldValidation('frasco', frascoStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frasco, fecha]);

    useEffect(() => {
        if(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined'){
            setTimeout(() => {
                onMultipleInputChange([{ target: { name: 'concentracion', value: String(frascoData.frasco.concentracion) } }, { target: { name: 'volumenAlergeno', value: String(frascoData.frasco.volumenAlergeno) } }, { target: { name: 'aplicaciones', value: frascoData.aplicaciones } }]);
                setMultipleFormStaticFieldValidation([{ name: 'concentracion', value: true }, { name: 'volumenAlergeno', value: true }]);
                setFormAplicacionesFieldValidation('aplicaciones', true);
                handleResetAplicaciones();
            }, 100);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frascoData])
    
    const onSubmit = (e) => {
        e.preventDefault();

        let sitioStatus = true;
        if(sitioData.sitiosFlag && typeof sitioData.sitios !== 'undefined' && sitioData.sitios.length > 0 && !sitioData.sitios.includes(sitio)){
            sitioStatus = false;
        }

        if(formTipoValidationStatus === 'checked' && formStaticValidationStatus === 'checked' && formFaseValidationStatus === 'checked' && formFrascoValidationStatus === 'checked' && isFormStaticValid != null && isFormStaticValid && isFormTipoValid != null && isFormTipoValid && isFormFaseValid != null && isFormFaseValid && isFormFrascoValid != null && isFormFrascoValid && sitioStatus){
            let data = { tipo, fase, fecha, frasco, concentracion, lado: ladoData.ladoFlag, lados: ladoData.lados, reaccion, volumenAlergeno, alergenos: alergenos.filter(alerg => alerg.alergeno !== '').map(alergeno => alergeno.alergeno), aplicaciones: aplicaciones.map(aplicacion => ({
                fecha: aplicacion.fecha,
                dosis: aplicacion.dosis,
                ladoAplicacion: aplicacion.ladoAplicacion,
                reaccion: aplicacion.reaccion,
                realizado: aplicacion.realizado
            })), comentarios };
            if(sitioData.sitiosFlag){
                data = {
                    ...data,
                    sitio
                };
            }
            createInmunoterapia({ data, id, success:  handleCancelar});
        }else{
            startFormStaticValidation();
            startFormTipoValidation();
            startFormFaseValidation();
            startFormFrascoValidation();
        }
    }

    return (
        <>
            {(isLoading)
                ? <Loader />
                : <>
                    {(isError)
                        ? <Error />
                        : <>
                            <div className="row">
                                <div className="col">
                                    <div className={styles.estudioContainer}>
                                        <form onSubmit={onSubmit}>
                                            <div className="row">
                                                <div className={(fases.length > 0) ? "col-md-12 col-lg-4" : "col"}>
                                                    <SelectInput
                                                        formFieldStatus={ formTipoFields['tipo'].status }
                                                        label="Tipo"
                                                        name="tipo"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormTipoFieldValidation }
                                                        value={tipo}
                                                        values={tipos}
                                                    />
                                                </div>
                                                {(faseReset && fases.length > 0) && <div className="col-md-12 col-lg-4">
                                                    <SelectInput
                                                        formFieldStatus={ formFaseFields['fase'].status }
                                                        label="Fase"
                                                        name="fase"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormFaseFieldValidation }
                                                        value={fase}
                                                        values={fases}
                                                    />
                                                </div> }
                                                {(fases.length > 0) && <div className="col-md-12 col-lg-4">
                                                    <DateTimeInput
                                                        formFieldStatus={ formStaticFields['fecha'].status }
                                                        label="Inicio"
                                                        name="fecha"
                                                        onChange={ handleOnFieldChange }
                                                        placeholder="dd/mm/aaaa hh:mm"
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ fecha }
                                                    />
                                                </div> }
                                            </div>
                                            <div className="row">
                                                {(frascoReset && frascos.length > 0) && <div className={(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') ? "col-md-12 col-lg-4" : "col"}>
                                                    <SelectInput
                                                        formFieldStatus={ formFrascoFields['frasco'].status }
                                                        label="Frasco"
                                                        name="frasco"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormFrascoFieldValidation }
                                                        value={frasco}
                                                        values={frascos.map(frasco => frasco.frasco)}
                                                    />
                                                </div> }
                                                {(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') && <div className="col-md-12 col-lg-4">
                                                    <TextInput
                                                        formFieldStatus={ formStaticFields['concentracion'].status }
                                                        label="Concentración"
                                                        name="concentracion"
                                                        onChange={ handleOnFieldChange }
                                                        required={ false }
                                                        requiredIndicator={ false }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ concentracion }
                                                    />
                                                </div> }
                                                {(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') && <div className="col-md-12 col-lg-4">
                                                    <TextInput
                                                        formFieldStatus={ formStaticFields['volumenAlergeno'].status }
                                                        label="Vol. alérgeno (ml)"
                                                        name="volumenAlergeno"
                                                        onChange={ handleOnFieldChange }
                                                        required={ false }
                                                        requiredIndicator={ false }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ volumenAlergeno }
                                                    />
                                                </div> }
                                            </div>
                                            <div className="row">
                                                {(sitioReset && typeof sitioData !== 'undefined' && sitioData.sitiosFlag && sitioData.sitios.length > 0 && typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') && <div className="col-md-12 col-lg-4">
                                                    <SelectInput
                                                        formFieldStatus="checked"
                                                        label="Sitio de aplicación"
                                                        name="sitio"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        value={sitio}
                                                        values={sitioData.sitios}
                                                    />
                                                </div> }
                                            </div>
                                            {(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') && <div className="row mt-1 mb-4">
                                                <div className="col">
                                                    <span className={styles.tabsSubHeader}>Alergenos</span>
                                                </div>
                                            </div>
                                            }
                                            {(typeof frascoData !== 'undefined' && typeof frascoData.frasco !== 'undefined') &&<div className="row">
                                                {(alergenosReset) && <div className="col">
                                                    <MultiFormAlergenosInmunoterapia
                                                        formsStatus="checked"
                                                        name="alergenos"
                                                        onFormsChange={ handleOnFieldChange }
                                                        tipo={tipo}
                                                        setFormValidation={ () => {} }
                                                        value={alergenos}
                                                    />
                                                </div>}
                                            </div>}
                                            {(typeof frascoData !== 'undefined' && typeof frascoData.aplicaciones !== 'undefined') && <div className="row mt-1 mb-4">
                                                <div className="col">
                                                    <span className={styles.tabsSubHeader}>Aplicaciones</span>
                                                </div>
                                            </div>
                                            }
                                            {(typeof frascoData !== 'undefined' && typeof frascoData.aplicaciones !== 'undefined' && aplicacionesReset) && <div className="row mt-1 mb-4">
                                                <div className="col">
                                                    <MultiFormAplicacionInmunoterapiaNueva
                                                        formsStatus="checked"
                                                        ladoFlag={ladoData.ladoFlag}
                                                        lados={ladoData.lados}
                                                        name="aplicaciones"
                                                        onFormsChange={ handleOnFieldChange }
                                                        reaccionFlag={reaccion}
                                                        setFormValidation={ () => {} }
                                                        value={aplicaciones}
                                                    />
                                                </div>
                                            </div>
                                            }
                                            <div className="row">
                                                <div className="col">
                                                    <TextAreaInput
                                                        formFieldStatus={ formStaticFields['comentarios'].status }
                                                        label="Comentarios"
                                                        name="comentarios"
                                                        onChange={ handleOnFieldChange }
                                                        required={ false }
                                                        requiredIndicator={ false }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ comentarios }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">
                                                    <LightButton
                                                        block={true}
                                                        disabled={isPending || formTipoValidationStatus === 'checking' || formStaticValidationStatus === 'checking' || formFaseValidationStatus === 'checking' || formFrascoValidationStatus === 'checking' || formAlergenosValidationStatus === 'checking' || formAplicacionesValidationStatus === 'checking'}
                                                        icon="X"
                                                        marginRight={true}
                                                        marginTop={false}
                                                        onClick={handleCancelar}
                                                        text="Cancelar"
                                                        type="button"
                                                    />
                                                </div>
                                                <div className="col-md-12 col-lg-6 mt-md-3 mt-lg-0">
                                                    <PrimaryButton
                                                        block={true}
                                                        disabled={isPending || formTipoValidationStatus === 'checking' || formStaticValidationStatus === 'checking' || formFaseValidationStatus === 'checking' || formFrascoValidationStatus === 'checking' || formAlergenosValidationStatus === 'checking' || formAplicacionesValidationStatus === 'checking' || typeof frascoData === 'undefined' || typeof frascoData.aplicaciones === 'undefined'}
                                                        icon="Check"
                                                        marginTop={false}
                                                        text="Guardar"
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

InmunoterapiaNueva.propTypes = {
    handleCancelar: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
}