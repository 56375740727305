import { useQuery } from "@tanstack/react-query"

import { getTipo } from "../services/pruebaalergia.service";

export const useTipo = () => {

    const tipoQuery = useQuery({
        queryKey: ['pruebaAlergiaTipo'],
        queryFn: () => getTipo(),
        staleTime: 0, // nulo
    });

    return {
        tipoQuery,
    }
}