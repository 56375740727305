import PropTypes from 'prop-types';

import { LightButton, TextInputPreview, MultiFilePreview, BadgePreview } from '../../../components';

import { colors } from '../../../types/types';
import styles from '../Modals.module.css';

export const DetallePruebaAlergiaModal = ({ data, hide }) => {
  return (
    <div>
        <div className="row mb-3">
            <div className="col">
                <div className={styles.modalHeader}>Detalle</div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Tipo de pruebas"
                    name="tipoPrueba"
                    value={data.tipoPrueba}
                />
            </div>
            <div className="col-md-12 col-lg-6">
                <BadgePreview
                    backgroundColor={(data.positiva ? colors.SUCCESS : colors.DANGER)}
                    label="Resultados"
                    name="positiva"
                    value={(data.positiva ? 'Postivas' : 'Negativas')}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Fecha"
                    name="fecha"
                    value={data.fechaFormato}
                />
            </div>
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Pruebas de alergia"
                    name="prueba"
                    value={data.prueba}
                />  
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Dispositivo"
                    name="dispositivo"
                    value={data.dispositivo}
                />
            </div>
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Sitio"
                    name="sitio"
                    value={data.sitio}
                />  
            </div>
        </div>
        {(data.tipoPrueba === 'Parches')
        ?<>
            {data.alergenos.map((alergeno, index) => <div key={index}>
                <div className="row">
                    <div className="col">
                        <TextInputPreview
                            label="Alergeno"
                            marginBottom={false}
                            name="alergeno"
                            value={alergeno.nombre}
                        />
                    </div>
                </div>
                <div className="row">
                    {(typeof alergeno.concentracion !== 'undefined') &&
                    <div className="col-md-12 col-lg-4">
                        <TextInputPreview
                            label="Concentración"
                            marginBottom={false}
                            // marginBottom={(index === data.alergenos.length - 1 && (typeof alergeno.categorias === 'undefined' || alergeno.categorias === '')) || (typeof alergeno.categorias !== 'undefined' && alergeno.categorias !== '')}
                            name="concentracion"
                            value={alergeno.concentracion}
                        />  
                    </div>
                    }
                    {(typeof alergeno.vehiculo !== 'undefined') &&
                    <div className="col-md-12 col-lg-4">
                        <TextInputPreview
                            label="Vehiculo"
                            marginBottom={false}
                            // marginBottom={(index === data.alergenos.length - 1 && (typeof alergeno.categorias === 'undefined' || alergeno.categorias === '')) || (typeof alergeno.categorias !== 'undefined' && alergeno.categorias !== '')}
                            name="vehiculo"
                            value={alergeno.vehiculo}
                        />  
                    </div>
                    }
                    {(typeof alergeno.resultados !== 'undefined' && alergeno.resultados !== '') &&
                    <div className="col-md-12 col-lg-4">
                        <TextInputPreview
                            label="Resultados"
                            marginBottom={false}
                            // marginBottom={(index === data.alergenos.length - 1 && (typeof alergeno.categorias === 'undefined' || alergeno.categorias === '')) || (typeof alergeno.categorias !== 'undefined' && alergeno.categorias !== '')}
                            name="resultado"
                            value={alergeno.resultados}
                        />  
                    </div>
                    }
                </div>
                {(typeof alergeno.categorias !== 'undefined' && alergeno.categorias !== '') &&
                <div className="row">
                    <div className="col">
                        <TextInputPreview
                            label="Categorias"
                            marginBottom={index === data.alergenos.length - 1}
                            name="categorias"
                            value={alergeno.categorias}
                        />  
                    </div>
                </div>
                }
                <div className="row">
                    <div className="col">
                        <hr className='mt-1' />
                    </div>
                </div>
            </div>)}
        </>
        :<>
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <TextInputPreview
                        showLabel={true}
                        label="Alergenos"
                        marginBottom={true}
                        name="alergeno"
                        value=""
                    />
                </div>
                <div className="col-md-12 col-lg-6">
                    <TextInputPreview
                        showLabel={true}
                        label="Resultados"
                        marginBottom={true}
                        name="resultado"
                        value=""
                    />  
                </div>
            </div>
            {data.alergenos.map((alergeno, index) => <div key={index}>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <TextInputPreview
                            showLabel={false}
                            label="Alergenos"
                            marginBottom={false}
                            name="alergeno"
                            value={alergeno.nombre}
                        />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <TextInputPreview
                            showLabel={false}
                            label="Resultados"
                            marginBottom={false}
                            name="resultado"
                            value={alergeno.resultados}
                        />  
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <hr className='mt-1' />
                    </div>
                </div>
            </div>)} 
        </>
        }
        <div className="row">
            <div className="col">
                <TextInputPreview
                    label="Comentarios"
                    name="cometarios"
                    value={(typeof data.comentarios !== 'undefined' && data.comentarios !== '') ? data.comentarios : '-'}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <MultiFilePreview
                    label="Archivos"
                    name="archivos"
                    files={(typeof data.archivos !== 'undefined') ? data.archivos : []}
                />
            </div>
        </div>
        <div className="row">
            <div className='col-xs-12 col-sm-12 col-md-6'></div>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                    block={true}
                    icon="X"
                    marginTop={false}
                    onClick={ hide }
                    text="Cerrar"
                    type="button"
                />
            </div>
        </div>
    </div>
  )
}

DetallePruebaAlergiaModal.propTypes = {
    data: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}