import PropTypes from 'prop-types';

import { LightButton, TextInputPreview } from '../../../components';

import styles from '../Modals.module.css';

export const HistorialInmunoterapiaModal = ({ data, hide }) => {
  return (
    <div>
        <div className="row mb-3">
            <div className="col">
                <div className={styles.modalHeader}>Historial</div>
            </div>
        </div>
        { data.aplicaciones.map((aplicacion, index) => {
            return <div key={aplicacion._id}>
                <div className="row">
                    <div className="col-md-12 col-lg-3">
                        <TextInputPreview
                            label="Fecha de aplicación"
                            name="fechaFormato"
                            value={(typeof aplicacion.fechaFormato !== 'undefined') ? aplicacion.fechaFormato : ''}
                            marginBottom={false}
                        />
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <TextInputPreview
                            label="Dosis"
                            name="dosis"
                            value={`${ aplicacion.dosis } ${ (typeof data.tipoDosis !== 'undefined') ? data.tipoDosis : '' }`}
                            marginBottom={false}
                        />
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <TextInputPreview
                            label="Sitio de aplicación"
                            name="sitio"
                            value={ `${ (typeof data.sitioAplicacion !== 'undefined') ? data.sitioAplicacion : '' } - ${ aplicacion.ladoAplicacion }` }
                            marginBottom={false}
                        />
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <TextInputPreview
                            label="Reacción"
                            name="reacción"
                            value={ (aplicacion.reaccion !== '') ? aplicacion.reaccion : '-' }
                            marginBottom={false}
                        />
                    </div>
                </div>
                { (index !== data.aplicaciones.length - 1) && <div className={styles.divider}></div> }
            </div>
        }) }
        <div className="row mt-4">
            <div className='col-xs-12 col-sm-12 col-md-6'></div>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                    block={true}
                    icon="X"
                    onClick={ hide }
                    text="Cerrar"
                    type="button"
                />
            </div>
        </div>
    </div>
  )
}

HistorialInmunoterapiaModal.propTypes = {
    data: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}