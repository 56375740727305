import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import { AplicacionForm } from './components/Aplicacion';
import { PrimaryRoundedButton } from '../..';

export const MultiFormAplicacionInmunoterapiaActiva = ({ handleCrear, handleEliminar, handleUpdate, ladoFlag = true, lados = [], reaccionFlag = true, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val._id, fecha: val.fecha, dosis: val.dosis, ladoAplicacion: val.ladoAplicacion, reaccion: val.reaccion, realizado: val.realizado, validation: true })) : []);

    const handleAddElement = () => {
        handleCrear();
    }

    const handleRemoveElement = (id) => {
        handleEliminar(id);
    }

    const handleUpdateElement = (data) => {
        let element = elements.find((elementAux) => (String(elementAux.id) === String(data.id)));
        if(typeof data.fecha !== 'undefined' && element.fecha !== data.fecha){
            handleUpdate(data);
        }
        if(typeof data.dosis !== 'undefined' && element.dosis !== data.dosis){
            handleUpdate(data);
        }
        if(typeof data.ladoAplicacion !== 'undefined' && element.ladoAplicacion !== data.ladoAplicacion){
            handleUpdate(data);
        }
        if(typeof data.reaccion !== 'undefined' && element.reaccion !== data.reaccion){
            handleUpdate(data);
        }
        if(typeof data.realizado !== 'undefined' && element.realizado !== data.realizado){
            handleUpdate(data);
        }
    }

    useEffect(() => {
        setElements(value.map(val => ({ id: val._id, fecha: val.fecha, dosis: val.dosis, ladoAplicacion: val.ladoAplicacion, reaccion: val.reaccion, realizado: val.realizado, validation: true })));
    }, [value]);

    return (
        <>
            {elements.map((element, index) => <AplicacionForm
                element={element}
                first={index === 0}
                index={index + 1}
                key={index}
                ladoFlag={ladoFlag}
                lados={lados}
                last={index === elements.length - 1}
                reaccionFlag={reaccionFlag}
                removeElement={handleRemoveElement}
                updateElement={handleUpdateElement}
            />)}
            <div className='centrar'>
                <PrimaryRoundedButton
                    icon="Plus"
                    onClick={handleAddElement}
                    type="button"
                />
            </div>
        </>
    )
}

MultiFormAplicacionInmunoterapiaActiva.propTypes = {
    handleCrear: PropTypes.func.isRequired,
    handleEliminar: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    ladoFlag: PropTypes.bool,
    lados: PropTypes.array,
    reaccionFlag: PropTypes.bool,
    value: PropTypes.array,
}