import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { AplicacionForm } from './components/Aplicacion';
import { PrimaryRoundedButton } from '../..';

import styles from './MultiFormAplicacionesML.module.css';

export const MultiFormAplicacionML = ({ formsStatus='checked', name, onFormsChange, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val.id, dosis: val.dosis, repeticiones: val.repeticiones, validation: true })) : [])

    const handleAddElement = (e) => {
        setElements([...elements, { id: moment().unix() + Math.random(), dosis: 0.01, repeticiones: 1, validation: false }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            id: element.id,
            dosis: element.dosis,
            repeticiones: element.repeticiones,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            <div className={styles.aplicacionesContainerScroll}>
                <div className={styles.aplicacionesContainer}>
                    {elements.map((element, index) => <div className={styles.aplicacionContainer + " me-4"} key={element.id}>
                        <AplicacionForm
                            element={element}
                            removeElement={handleRemoveElement}
                            onFormChange={handleSetElementData}
                            first={index === 0}
                            formStatus={formsStatus}
                        />
                    </div>)}
                    <div className={styles.aplicacionContainer} key={1}>
                        <div className={styles.addBtnContainer}>
                            <PrimaryRoundedButton
                                icon="Plus"
                                onClick={handleAddElement}
                                type="button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

MultiFormAplicacionML.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}