import { useQuery } from "@tanstack/react-query"

import { getFase } from "../services/inmunoterapia.service";

export const useInmunoterapiaFase = (tipo) => {

    const faseQuery = useQuery({
        queryKey: ['inmunoterapiaFase'],
        queryFn: () => getFase(tipo),
        staleTime: 0, // nulo
    });

    return {
        faseQuery,
    }
}