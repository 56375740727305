import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { deletePacienteInmunoterapia } from "../services/inmunoterapia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteInmunoterapiaEliminar = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const deleteInmunoterapiaMutation = useMutation({
        mutationFn: deletePacienteInmunoterapia,
        onSuccess: (inmunoterapia, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/proxima', id] });
            dispatch(successNotification('Inmunoterapia eliminada'));
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        deleteInmunoterapiaMutation,
    }
}